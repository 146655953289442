import React, { useState } from "react";
import "./Main.scss";
import {
  IkaBataImg,
  HamburgerImg,
  SakeImg,
  TenpuraImg,
  NaikanImg,
  TyayagaiImg,
  GaikanImg,
  NikumakiImg,
} from "./../assets/jpeg";

const Main = () => {
  const [drinkFlag, setDrinkFlag] = useState(false);
  return (
    <div className="main-page">
      <div id="feature" className="feature contents">
        <h3 className="title">
          <span className="border">こだわり</span>
        </h3>

        <div className="explain explain1">
          <img src={SakeImg} alt="こだわり" />
          <div>
            <p>
              当店では、地元の新鮮な食材を使った料理と地元産のお酒を提供しています。旬の食材を使ったメニューが豊富で、地元ならではの味わいをお楽しみいただけます。
            </p>
          </div>
        </div>
        <div className="explain explain2">
          <div>
            <p className="switch">
              当店は、落ち着いた雰囲気の店内で、美味しいお酒と料理をゆっくりとお楽しみいただけます。もちろんお一人様でも気兼ねなくお入りいただけます。また、貸切も可能ですので、ご友人やご家族との大切なイベントに最適です。
            </p>
          </div>
          <img src={NaikanImg} alt="こだわり" />
        </div>
        <div className="explain explain3">
          <img src={TyayagaiImg} alt="こだわり" />
          <div>
            <p>
              当店は、観光の後にふらっと立ち寄っていただける好アクセスの場所にあります。西茶屋街まで徒歩30秒、片町まではわずか5分という便利な立地です。また、当店で飲み終えた後は、繁華街でさらに楽しむこともできます。ぜひ散策してみてください。当店でも、お客様が楽しめるよう、美味しいお酒と料理をご用意してお待ちしております。
            </p>
          </div>
        </div>
      </div>

      <div id="menu" className="menu contents">
        <h3 className="title">
          <span className="border">メニュー</span>
        </h3>
        <p className="category">〜おすすめ〜</p>
        <div className="recommend">
          <h3>ほろ酔いセット</h3>
          <p>生ビール2杯＋小鉢付き</p>
          <p>サービスタイム　17:00~19:00</p>
          <p className="price">1,200円（税抜）</p>
        </div>

        <p className="category">〜お食事〜</p>
        <div className="food-list">
          <div className="food">
            <div>
              <h3>手作りハンバーグ</h3>
            </div>
            <img src={HamburgerImg} alt="食品" />
          </div>
          <div className="food">
            <div>
              <h3>季節の天ぷら盛り合わせ</h3>
            </div>
            <img src={TenpuraImg} alt="食品" />
          </div>
          <div className="food">
            <div>
              <h3>新生姜の肉巻き</h3>
            </div>
            <img src={NikumakiImg} alt="食品" />
          </div>
          <div className="food">
            <div>
              <h3>イカのバター炒め</h3>
            </div>
            <img src={IkaBataImg} alt="食品" />
          </div>
        </div>
        <p className="food-more">
          ※その他、軽いおつまみからボリュームたっぷりの料理まで、幅広く取り揃えております。
        </p>

        <p className="category category-drink">〜お飲み物〜</p>
        <div className="drink-list list-pc">
          <div className="drink">
            <h4>ビール</h4>
            <div className="drink-menu">
              <p>キリン一番搾り</p>
              <p>530円</p>
            </div>
            <div className="drink-menu">
              <p>キリンラガービール 中瓶</p>
              <p>630円</p>
            </div>
            <div className="drink-menu">
              <p>アサヒスーパードライ 中瓶</p>
              <p>630円</p>
            </div>
            <div className="drink-menu">
              <p>サッポロ黒ラベル 中瓶</p>
              <p>630円</p>
            </div>{" "}
            <div className="drink-menu">
              <p>サッポロ赤星 中瓶</p>
              <p>630円</p>
            </div>
            <div className="drink-menu">
              <p>キリンゼロイチ</p>
              <p>400円</p>
            </div>
          </div>
          <div className="drink">
            <h4>清酒</h4>
            <div className="drink-menu">
              <p>立山 本醸造</p>
              <p>500円</p>
            </div>
            <div className="drink-menu">
              <p>特別本醸造 加賀太郎 太郎</p>
              <p>580円</p>
            </div>
            <div className="drink-menu">
              <p>黒帯 特別純米（悠々）</p>
              <p>680円</p>
            </div>
            <div className="drink-menu">
              <p>竹葉 純米</p>
              <p>680円</p>
            </div>
            <div className="drink-menu">
              <p>宗玄　純米</p>
              <p>730円</p>
            </div>
            <div className="drink-menu">
              <p>手取川 山廃純米</p>
              <p>730円</p>
            </div>
            <div className="drink-menu">
              <p>加賀鳶 山廃純米</p>
              <p>730円</p>
            </div>
            <div className="drink-menu">
              <p>中村屋 純米吟醸</p>
              <p>900円</p>
            </div>
          </div>
          <div className="drink">
            <h4>焼酎（ロック・水割り）</h4>
            <div className="drink-menu">
              <p>いいちこ （麦）</p>
              <p>500円</p>
            </div>
            <div className="drink-menu">
              <p>能登ちょんがりぶし （麦）</p>
              <p>600円</p>
            </div>
            <div className="drink-menu">
              <p>閻魔 （麦）</p>
              <p>600円</p>
            </div>
            <div className="drink-menu">
              <p>黒霧島 （芋）</p>
              <p>500円</p>
            </div>
            <div className="drink-menu">
              <p>薩摩古秘 （芋）</p>
              <p>600円</p>
            </div>
            <p>※ソーダ割りは、+80円になります。</p>
          </div>
          <div className="drink">
            <h4>ウイスキー（ハイボール・水割り・ロック）</h4>
            <div className="drink-menu">
              <p>角</p>
              <p>500円</p>
            </div>
            <div className="drink-menu">
              <p>デュワーズ</p>
              <p>500円</p>
            </div>
            <div className="drink-menu">
              <p>知多</p>
              <p>900円</p>
            </div>
            <h4>ワイン</h4>
            <div className="drink-menu">
              <p>白ワイン 小ボトル（250ml）</p>
              <p>800円</p>
            </div>
            <div className="drink-menu">
              <p>赤ワイン 小ボトル（250ml）</p>
              <p>800円</p>
            </div>
            <div className="drink-menu">
              <p>シャンパンボトル</p>
              <p>13000円〜</p>
            </div>
          </div>

          <div className="drink">
            <h4>サワー・チューハイ・その他</h4>
            <div className="drink-menu">
              <p>氷結®︎レモン</p>
              <p>450円</p>
            </div>
            <div className="drink-menu">
              <p>氷結®︎無糖レモン</p>
              <p>450円</p>
            </div>
            <div className="drink-menu">
              <p>ライムサワー</p>
              <p>450円</p>
            </div>
            <div className="drink-menu">
              <p>カルピスサワー</p>
              <p>450円</p>
            </div>
            <div className="drink-menu">
              <p>緑茶ハイ</p>
              <p>450円</p>
            </div>
            <div className="drink-menu">
              <p>ウーロンハイ</p>
              <p>450円</p>
            </div>
            <div className="drink-menu">
              <p>加賀梅酒</p>
              <p>600円</p>
            </div>
            <div className="drink-menu">
              <p>カシス</p>
              <p>400円</p>
            </div>
          </div>
          <div className="drink">
            <h4>ソフトドリンク</h4>
            <div className="drink-menu">
              <p>コーラ</p>
              <p>350円</p>
            </div>
            <div className="drink-menu">
              <p>ジンジャーエール</p>
              <p>350円</p>
            </div>
            <div className="drink-menu">
              <p>オレンジジュース</p>
              <p>350円</p>
            </div>
            <div className="drink-menu">
              <p>グレープフルーツジュース</p>
              <p>350円</p>
            </div>
            <div className="drink-menu">
              <p>カルピス</p>
              <p>350円</p>
            </div>
            <div className="drink-menu">
              <p>烏龍茶</p>
              <p>350円</p>
            </div>
            <div className="drink-menu">
              <p>緑茶</p>
              <p>350円</p>
            </div>
            <div className="drink-menu">
              <p>金沢湯涌サイダー柚乙女</p>
              <p>500円</p>
            </div>
          </div>
        </div>
        <div className="drink-list list-sp">
          <div className="drink">
            <h4>ビール</h4>
            <div className="drink-menu">
              <p>キリン一番搾り</p>
              <p>530円</p>
            </div>
            <div className="drink-menu">
              <p>キリンラガービール 中瓶</p>
              <p>630円</p>
            </div>
            <div className="drink-menu">
              <p>アサヒスーパードライ 中瓶</p>
              <p>630円</p>
            </div>
            <div className="drink-menu">
              <p>キリンゼロイチ</p>
              <p>400円</p>
            </div>
          </div>
          <div className="drink">
            <h4>清酒</h4>
            <div className="drink-menu">
              <p>立山 本醸造</p>
              <p>500円</p>
            </div>
            <div className="drink-menu">
              <p>特別本醸造 加賀太郎 太郎</p>
              <p>580円</p>
            </div>
            <div className="drink-menu">
              <p>黒帯 特別純米（悠々）</p>
              <p>680円</p>
            </div>
            <div className="drink-menu">
              <p>竹葉 純米</p>
              <p>680円</p>
            </div>
            <div className="drink-menu">
              <p>宗玄　純米</p>
              <p>730円</p>
            </div>
            <div className="drink-menu">
              <p>手取川 山廃純米</p>
              <p>730円</p>
            </div>
            <div className="drink-menu">
              <p>加賀鳶 山廃純米</p>
              <p>730円</p>
            </div>
            <div className="drink-menu">
              <p>中村屋 純米吟醸</p>
              <p>900円</p>
            </div>
          </div>
          {drinkFlag ? (
            <>
              <div className="drink" v-show="isOpenFlag.drink">
                <h4>焼酎（ロック・水割り）</h4>
                <div className="drink-menu">
                  <p>いいちこ （麦）</p>
                  <p>500円</p>
                </div>
                <div className="drink-menu">
                  <p>能登ちょんがりぶし （麦）</p>
                  <p>600円</p>
                </div>
                <div className="drink-menu">
                  <p>閻魔 （麦）</p>
                  <p>600円</p>
                </div>
                <div className="drink-menu">
                  <p>黒霧島 （芋）</p>
                  <p>500円</p>
                </div>
                <div className="drink-menu">
                  <p>薩摩古秘 （芋）</p>
                  <p>600円</p>
                </div>
                <p>※ソーダ割りは、+80円になります。</p>
              </div>
              <div className="drink" v-show="isOpenFlag.drink">
                <h4>ウイスキー（ハイボール・水割り・ロック）</h4>
                <div className="drink-menu">
                  <p>角</p>
                  <p>500円</p>
                </div>
                <div className="drink-menu">
                  <p>デュワーズ</p>
                  <p>500円</p>
                </div>
                <div className="drink-menu">
                  <p>知多</p>
                  <p>900円</p>
                </div>
                <h4>ワイン</h4>
                <div className="drink-menu">
                  <p>白ワイン 小ボトル（250ml）</p>
                  <p>800円</p>
                </div>
                <div className="drink-menu">
                  <p>赤ワイン 小ボトル（250ml）</p>
                  <p>800円</p>
                </div>
                <div className="drink-menu">
                  <p>シャンパンボトル</p>
                  <p>13000円〜</p>
                </div>
              </div>

              <div className="drink">
                <h4>サワー・チューハイ・その他</h4>
                <div className="drink-menu">
                  <p>氷結®︎レモン</p>
                  <p>450円</p>
                </div>
                <div className="drink-menu">
                  <p>氷結®︎無糖レモン</p>
                  <p>450円</p>
                </div>
                <div className="drink-menu">
                  <p>ライムサワー</p>
                  <p>450円</p>
                </div>
                <div className="drink-menu">
                  <p>カルピスサワー</p>
                  <p>450円</p>
                </div>
                <div className="drink-menu">
                  <p>緑茶ハイ</p>
                  <p>450円</p>
                </div>
                <div className="drink-menu">
                  <p>ウーロンハイ</p>
                  <p>450円</p>
                </div>
                <div className="drink-menu">
                  <p>加賀梅酒</p>
                  <p>600円</p>
                </div>
                <div className="drink-menu">
                  <p>カシス</p>
                  <p>450円</p>
                </div>
              </div>
              <div className="drink" v-show="isOpenFlag.drink">
                <h4>ソフトドリンク</h4>
                <div className="drink-menu">
                  <p>コーラ</p>
                  <p>350円</p>
                </div>
                <div className="drink-menu">
                  <p>ジンジャーエール</p>
                  <p>350円</p>
                </div>
                <div className="drink-menu">
                  <p>オレンジジュース</p>
                  <p>350円</p>
                </div>
                <div className="drink-menu">
                  <p>グレープフルーツジュース</p>
                  <p>350円</p>
                </div>
                <div className="drink-menu">
                  <p>カルピス</p>
                  <p>350円</p>
                </div>
                <div className="drink-menu">
                  <p>烏龍茶</p>
                  <p>350円</p>
                </div>
                <div className="drink-menu">
                  <p>緑茶</p>
                  <p>350円</p>
                </div>
                <div className="drink-menu">
                  <p>金沢湯涌サイダー柚乙女</p>
                  <p>500円</p>
                </div>
              </div>
            </>
          ) : null}
          {!drinkFlag ? (
            <button
              className="drink-open-button"
              onClick={() => setDrinkFlag(true)}
            >
              開く
            </button>
          ) : null}
          {drinkFlag ? (
            <button
              className="drink-cose-button"
              onClick={() => setDrinkFlag(false)}
            >
              閉じる
            </button>
          ) : null}
        </div>
      </div>

      <div id="information" className="information contents">
        <h3 className="title">
          <span className="border">店舗情報</span>
        </h3>
        <div className="info-flex">
          <div className="info">
            <table>
              <tbody>
                <tr>
                  <td className="td-width">営業時間</td>
                  <td>
                    17:00~23:00（ラストオーダー22:30）
                    <br />
                    営業時間は変更となる場合がございますので、ご来店前に店舗にご確認ください。
                  </td>
                </tr>
                <tr>
                  <td>定休日</td>
                  <td>
                    毎週月曜日・第3日曜日
                    <br />
                    定休日は変更となる場合がございますので、ご来店前に店舗にご確認ください。
                  </td>
                </tr>
                <tr>
                  <td>座席数</td>
                  <td>カウンター6席</td>
                </tr>
                <tr>
                  <td>貸切</td>
                  <td>可能　※要予約</td>
                </tr>
                <tr>
                  <td>禁煙・喫煙</td>
                  <td>電子タバコのみ可</td>
                </tr>
                <tr>
                  <td>予約可否</td>
                  <td>
                    可能
                    <br />
                    ご予約はお電話にて受け付けております。
                  </td>
                </tr>
                <tr>
                  <td>支払い方法</td>
                  <td>現金・PayPayのみ</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="gaikan">
            <img src={GaikanImg} alt="外観" />
          </div>
        </div>

        <div id="access" className="access contents">
          <h3 className="title">
            <span className="border">アクセス</span>
          </h3>
          <div className="access-info">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1636.0992981678166!2d136.6463164644141!3d36.55753383490032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff83539e87185d7%3A0x626dcb6c2c4c3a74!2z6YeR5r6k44Gh44KH44GE5ZGR44G_5YemIOOBn-OBq-Wxiw!5e0!3m2!1sja!2sjp!4v1686304059753!5m2!1sja!2sjp"
              title="taniya-map"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            <table className="info">
              <tbody>
                <tr>
                  <td className="td-width">住所</td>
                  <td>石川県金沢市野町2丁目32-8</td>
                </tr>
                <tr>
                  <td>交通手段</td>
                  <td>野町駅　徒歩15分</td>
                </tr>
                <tr>
                  <td>駐車場</td>
                  <td>有：専用無料１台</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
