import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <p className="inquiry">お電話でのお問合せ</p>
      <a className="tel" href="tel:0762184146">
        TEL : 076-218-4146
      </a>
      <p className="attention">
        こ予約はお電話からのみとなりまが、ご了承ください。
      </p>
      <p className="sign">©︎ちょい呑み処 たに屋</p>
    </footer>
  );
};

export default Footer;
