import React, { useState } from "react";
import "./Header.scss";
import { CallMark, CloseMark, MapMark, MenuMark } from "../assets/svg";

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const clickOpen = () => {
    setIsOpenMenu(true);
  };
  const clickClose = () => {
    setIsOpenMenu(false);
  };

  const clickMap = () => {
    window.open("https://goo.gl/maps/whVqhz2XBUVNZVdD7", "_blank");
  };

  return (
    <header>
      <div className="pc">
        <div className="header-top">
          <h1>
            <span>金澤ちょい呑み処</span> たに屋
          </h1>
          <div className="reserve">
            <p>お電話はこちらから</p>
            <a className="tel" href="tel:0762184146" style={{ color: "white" }}>
              TEL : 076-218-4146
            </a>
          </div>
        </div>
        <nav>
          <ul className="list">
            <li className="first">
              <a href="#feature">こだわり</a>
            </li>
            <li>
              <a href="#menu">メニュー</a>
            </li>
            <li>
              <a href="#information">店舗情報</a>
            </li>
            <li>
              <a href="#access">アクセス</a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="sp">
        <h1>
          金澤ちょい呑み処 <span>たに屋</span>
        </h1>
        <div className="icon">
          <button>
            <a href="tel:076-218-4146">
              <img src={CallMark} alt="" className="mark" />
            </a>
          </button>

          {/* <button onClick="window.open('https://goo.gl/maps/whVqhz2XBUVNZVdD7')"> */}
          <button onClick={clickMap}>
            <img src={MapMark} alt="" className="mark" />
          </button>
          <button onClick={clickOpen}>
            <img src={MenuMark} alt="" className="mark" />
          </button>
        </div>
        {isOpenMenu ? (
          <div class="hamburger">
            <button onClick={clickClose}>
              <img src={CloseMark} alt="" className="mark close-mark" />
            </button>
            <nav>
              <ul class="hamburger-list">
                <li>
                  <a href="#feature" onClick={clickClose}>
                    こだわり
                  </a>
                </li>
                <li>
                  <a href="#menu" onClick={clickClose}>
                    メニュー
                  </a>
                </li>
                <li>
                  <a href="#information" onClick={clickClose}>
                    店舗情報
                  </a>
                </li>
                <li>
                  <a href="#access" onClick={clickClose}>
                    アクセス
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
